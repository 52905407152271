// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Popover, Row, Col } from 'antd';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { type FC, useState, type FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { type Authenticator } from './store/types';
import ImprId from '../../assets/icons/imprId.svg?react';
import ProxCard from '../../assets/icons/proxCard.svg?react';
import FaceId from '../../assets/icons/face.svg?react';
import ImprPin from '../../assets/icons/pin.svg?react';
import Applications from '../../assets/icons/applications.svg?react';
import { computeDateDiffInDays, DATE_TIME_FORMAT } from '../../utils/DateUtils';
import classes from './AuthenticatorPopup.module.less';
import SaveDiscardModal from '../modals/save-discard-modal/SaveDiscardModal';
import ThreeDots from '../../assets/icons/3-dots.svg?react';
import { useKeyCallBack } from '../hooks';
import { AuthenticatorType, type CredentialInfo } from './types';
import {
  type CredentialsFindByUserIdRequest,
  type UserAppCredentialDeleteRequest,
} from '../../api/Credentials/types';
import getConfig from '../../appConfigUtils';

const { USER_CREDENTIALS_ENABLED } = getConfig();

const credentialsChanged = (
  creds1?: CredentialInfo[],
  creds2?: CredentialInfo[],
) => (creds1 || []).length !== (creds2 || []).length;

const AuthenticatorPopup: FC<{
  userId: string;
  username: string;
  findCredentials: (
    filter: CredentialsFindByUserIdRequest,
  ) => Promise<CredentialInfo[] | undefined>;
  onRemoveAuthenticator: (authenticator: Authenticator) => void;
  onRemoveCredential: (credentialId: string, appType: string) => void;
  authenticators?: Authenticator[];
}> = ({
  userId,
  username,
  authenticators,
  findCredentials,
  onRemoveAuthenticator,
  onRemoveCredential,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [authToRemove, setAuthToRemove] = useState<Authenticator>();
  const [credentials, setCredentials] = useState<CredentialInfo[]>();
  const [credentialToRemove, setCredentialToRemove] =
    useState<UserAppCredentialDeleteRequest>({
      credentialId: '',
      appType: '',
    });
  const { credentialId, appType } = credentialToRemove;

  const computeDateDiff = (dateStr: string) => {
    const days = computeDateDiffInDays(dateStr, DATE_TIME_FORMAT);
    if (days === 0) {
      return t('users.authenticators.today');
    } else if (days === 1) {
      return t('users.authenticators.yesterday');
    } else if (days > 365) {
      return t('users.authenticators.lastYear');
    } else {
      return t('users.authenticators.last-used', {
        lastUsed: days,
      });
    }
  };

  // Shared handlers
  const handleOpenChange = (o: boolean) => {
    if (!visible) {
      findCredentials({
        userId,
      })
        .then(res => {
          console.log(
            'Api response (credentials): ',
            res,
            ' -- Current credentials: ',
            credentials,
            ' -- Different? ',
            credentialsChanged(credentials, res),
          );
          setCredentials(s => (credentialsChanged(s, res) ? res : s));
        })
        .catch(err => {
          console.error('Error fetching learned credentials: ', err);
        });
    }

    if (!o) {
      setConfirmationVisible(false);
    }
    setVisible(o);
  };

  const cancel = () => {
    setConfirmationVisible(false);
    setVisible(false);
  };
  useKeyCallBack(['Escape'], () => {
    handleOpenChange(false);
  });

  // Authenticator handler
  const confirmAuthenticator = (authenticator: Authenticator, e: FormEvent) => {
    e.preventDefault();
    setConfirmationVisible(true);
    setVisible(false);
    setAuthToRemove(authenticator);
  };

  const removeAuthenticator = () => {
    if (authToRemove) {
      onRemoveAuthenticator(authToRemove);
    }
    setVisible(false);
    setConfirmationVisible(false);
  };

  // Learned credential handlers
  const confirmCredential = (
    credentialId: string,
    appType: string,
    e: FormEvent,
  ) => {
    e.preventDefault();
    setConfirmationVisible(true);
    setVisible(false);
    setCredentialToRemove({ credentialId, appType });
  };

  const removeCredential = () => {
    if (credentialId.length > 0 && appType.length > 0) {
      onRemoveCredential(credentialId, appType);
    }
    setCredentials(c => (c === undefined ? c : undefined));
    setVisible(false);
    setConfirmationVisible(false);
  };

  const getAuthenticatorIcon = (auth: Authenticator) => {
    switch (auth.factorType) {
      case AuthenticatorType.PROX:
        return <ProxCard data-testid="autheticator-prox-icon" />;
      case AuthenticatorType.IMPRIVATA_ID:
        return <ImprId data-testid="autheticator-imprid-icon" />;
      case AuthenticatorType.IMPRIVATA_PIN:
        return <ImprPin data-testid="autheticator-pin-icon" fill="#333" />;
      case AuthenticatorType.FACE_ID:
        return <FaceId data-testid="autheticator-face-icon" />;
      default:
        return null;
    }
  };

  const getAuthenticatorName = (auth: Authenticator) => {
    switch (auth.factorType) {
      case AuthenticatorType.PROX:
        return t('users.authenticators.proxcard', {
          card: auth.shortName,
        });
      case AuthenticatorType.IMPRIVATA_ID:
        return t('users.authenticators.imprivata-id', {
          id: auth.shortName,
        });
      case AuthenticatorType.IMPRIVATA_PIN:
        return t('users.authenticators.imprivata-pin');
      case AuthenticatorType.FACE_ID:
        return t('users.authenticators.face-id');
      default:
        return null;
    }
  };

  const authenticatorContent = (
    <>
      {authenticators !== undefined ? (
        <div data-testid="athenticators-title">
          {t('users.authenticators.title')}
        </div>
      ) : null}
      {authenticators ? (
        authenticators.map(auth => (
          <div
            key={auth.authenticatorId}
            className={classes.authCredential}
            data-testid="authenticator-content"
          >
            <Row justify="space-around" align="middle">
              <Col span={2}>
                <div className={classes.authCredentialIcon}>
                  {getAuthenticatorIcon(auth)}
                </div>
              </Col>
              <Col span={16}>
                <div className={classes.subTitle}>
                  {getAuthenticatorName(auth)}
                </div>
                <span className={classes.authCredUsedLabel}>
                  {computeDateDiff(auth.lastUsedAt)}
                </span>
              </Col>
              <Col>
                <Link
                  className={classes.removeLabel}
                  data-testid="remove-authenticator-button"
                  to="#"
                  onClick={confirmAuthenticator.bind(null, auth)}
                >
                  {t('users.authenticators.remove')}
                </Link>
              </Col>
              {confirmationVisible && authToRemove === auth && (
                <SaveDiscardModal
                  title={t('users.authenticators.confirm-remove.title')}
                  cancelText={t('actions.dont-remove')}
                  okText={t('actions.remove')}
                  content={t('users.authenticators.confirm-remove.content', {
                    authenticatorName: getAuthenticatorName(authToRemove),
                  })}
                  open={true}
                  onSave={() => {
                    removeAuthenticator();
                  }}
                  onDiscard={cancel}
                  onClose={cancel}
                  closable={false}
                />
              )}
            </Row>
          </div>
        ))
      ) : (
        <>
          <p
            className={classes.noAuthMessage}
            data-testid="no-authenticators-message"
          >
            {t('users.authenticators.none')}
          </p>
        </>
      )}
    </>
  );

  const learnCredentialsContent = (
    <>
      {credentials !== undefined && USER_CREDENTIALS_ENABLED ? (
        <div
          className={classes.credsTitle}
          data-testid="learned-credentials-title"
        >
          {t('users.learned-credentials.title')}
        </div>
      ) : null}
      {credentials && USER_CREDENTIALS_ENABLED ? (
        credentials.map(cred => (
          <div
            key={cred.credentialId}
            className={classes.authCredential}
            data-testid="credentials-content"
          >
            <Row justify="space-around" align="middle">
              <Col span={2}>
                <div className={classes.authCredentialIcon}>
                  <Applications data-testid="credential-learning-icon" />
                </div>
              </Col>
              <Col span={16}>
                <div className={classes.subTitle}>{cred.displayName}</div>
                <span className={classes.authCredUsedLabel}>
                  {cred.username}
                </span>
              </Col>
              <Col>
                <Link
                  className={classes.removeLabel}
                  data-testid="remove-credential-button"
                  to="#"
                  onClick={confirmCredential.bind(
                    null,
                    cred.credentialId,
                    cred.appType,
                  )}
                >
                  {t('users.learned-credentials.remove')}
                </Link>
              </Col>
              {confirmationVisible &&
                credentialId === cred.credentialId &&
                appType === cred.appType && (
                  <SaveDiscardModal
                    title={t('users.learned-credentials.confirm-remove.title')}
                    cancelText={t('actions.dont-remove')}
                    okText={t('actions.remove')}
                    content={t(
                      'users.learned-credentials.confirm-remove.content',
                      {
                        username: username,
                        appName: cred.displayName,
                      },
                    )}
                    open={true}
                    onSave={() => {
                      removeCredential();
                    }}
                    onDiscard={cancel}
                    onClose={cancel}
                    closable={false}
                  />
                )}
            </Row>
          </div>
        ))
      ) : (
        <>
          <p
            className={classes.noCredMessage}
            data-testid="no-credentials-message"
          >
            {t('users.learned-credentials.none')}
          </p>
        </>
      )}
    </>
  );

  return (
    <Popover
      getPopupContainer={trigger => trigger.parentElement || document.body}
      placement="bottomLeft"
      overlayClassName={classes.popover}
      className={classes.popover}
      data-testid="authenticators-popup"
      content={
        <>
          {authenticatorContent}
          {learnCredentialsContent}
        </>
      }
      trigger="click"
      open={visible}
      autoAdjustOverflow={true}
      onOpenChange={handleOpenChange}
    >
      <Button
        variant={ButtonVariant.TEXT}
        icon={<Icon component={ThreeDots} />}
        data-testid="actions-btn"
        dots
      />
    </Popover>
  );
};

export default AuthenticatorPopup;
